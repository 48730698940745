<template>
  <div class="login">
    <el-form ref="ruleForm" :model="form" :rules="rules">
      <el-form-item prop="username" style="margin-top: 30px">
        <el-input
            v-model="form.username"
            class="myInput"
            clearable
            placeholder="请输入手机号"
            prefix-icon="el-icon-mobile-phone"
        ></el-input>
      </el-form-item>
      <el-form-item class="code-box" prop="sms">
        <el-input
            v-model="form.sms"
            class="myInput"
            clearable
            placeholder="请输入短信验证码"
            prefix-icon="el-icon-chat-line-square"
        ></el-input>
        <div v-if="!state.smsSendBtn" class="code" @click="verify">
          获取验证码
        </div>
        <div v-else class="code">{{ state.time }}s</div>
      </el-form-item>
      <div class="agreement">
        <el-checkbox v-model="checked" class="myCheckbox" size="medium"></el-checkbox>
        <div>
          同意
          <a @click="$router.push({ path: '/userAgreement' })"
          >《跨信通用户协议》</a
          >和<a @click="$router.push({ path: '/privacyPolicy' })"
        >《隐私保护政策》</a
        >
        </div>
      </div>
      <el-form-item class="mengDiv">
        <div v-if="meng" class="meng">
          <div>提示</div>
          <div>
            <div v-if="pageYeFlag">跳转到客户端</div>
            <div v-else>跳转到小程序</div>
          </div>
          <div class="button">
            <button class="btn" style="marginright: 20px" @click="noOpen">
              取消
            </button>
            <button class="btn" @click="goPageYe">确定</button>
          </div>
        </div>
        <el-button class="submit" @click="onSubmit">快速注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import '../assets/js/reset'
import {Decrypt} from '../utils/secret'
// import tCaptcha from '@/common/mixins/tCaptcha'
import {
  getSmsCaptcha,
  register,
  generatescheme,
  browseRecords,
} from '../api/login'
import qs from 'qs'

export default {
  // mixins: [tCaptcha],
  data() {
    //这个方法要写在用到这个方法的地方的前面
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3-9][0-9]\d{8}$/;
        // 这里定义的reg变量没有加引号，所以是一种特殊的数据类型，即正则，可以直接用test()方法
        if (reg.test(value)) {
          callback();
        } else {
          // 具体错误信息没有提示
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      pageYeFlag: false,
      meng: false,
      uid: '',
      suid: '',
      goUrl: '',
      form: {
        username: '',
        password: '',
        sms: '',
      },
      rules: {
        username: [
          {required: true, min: 11, message: '请输入正确的手机号', validator: validatePhone, trigger: 'blur'},

        ],
        password: [
          {
            required: true,
            message: '请输入由数字和字母组成的8-20位的密码',
            trigger: 'blur',
          },
        ],
        sms: [{required: true, message: '请输入验证码', trigger: 'blur'}],
      },
      checked: false,
      state: {
        time: 60,
        smsSendBtn: false,
      },
      captchaVo: '',
      extendedInformation: '',
      extendAccount: ''
    }
  },
  created() {
    let script = document.querySelector('script#TCaptcha')
    if (!script) {
      script = document.createElement('script')
      script.id = 'TCaptcha'
      script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
      document.body.appendChild(script)
    }
    console.log()
    console.log(GetRequest()['code'])
    // this.getUID();
    this.generateschemeInfo()
    if (this.$route.query.extendedInformation) {
      this.extendedInformation = this.$route.query.extendedInformation
      this.browseRecords()
    }
    if (this.$route.query.customerAccount) {
      this.extendAccount = Decrypt(this.$route.query.customerAccount)
    }
  },
  methods: {
    slideCallback(data) {
      console.log(data)
      this.captchaVo = data
      this.getCode()
    },
    // 获取的是小程序要跳转的地址
    generateschemeInfo() {
      generatescheme().then((res) => {
        if (res.data.code == 200) {
          this.goUrl = res.data.data.openlink
        }
      })
    },
    // 获取uid
    getUID() {
      let url = window.location.href.split('?')[1]
      if (url) {
        if (url.split('=')[0] == 'UID') {
          this.uid = url.split('=')[1]
        } else {
          if (url.split('=')[0] == 'SUID') {
            this.suid = url.split('=')[1]
          }
        }
      }
    },

    getCode() {
      var valid = true;
      if (!this.form.username) {
        this.$message.warning('请输入手机号')
        valid = false;
      } else {

        const reg = /^1[3-9][0-9]\d{8}$/;
        // 这里定义的reg变量没有加引号，所以是一种特殊的数据类型，即正则，可以直接用test()方法
        if (!reg.test(this.form.username)) {
          this.$message.warning('请输入正确的手机号')
          valid = false;
        }
      }
      if (!valid) {
        this.captchaVo = ''
        return
      }

      this.state.smsSendBtn = true
      const interval = window.setInterval(() => {
        if (this.state.time-- <= 0) {
          this.state.time = 60
          this.state.smsSendBtn = false
          window.clearInterval(interval)
        }
      }, 1000)
      getSmsCaptcha({acsRequest: this.captchaVo, phoneNumber: this.form.username}).then(
          (res) => {
            if (res.data.code == 200) {
              this.$message.success('验证码获取成功')
            } else {
              console.log("getSmsCaptcha:", res)
              this.$message.warning(res.msg)
            }
          }
      )
    },
    // 根据当前设备进行跳转
    goPAGE() {
      if (
          navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
      ) {
        /*window.location.href="你的手机版地址";*/
        // 就跳到小程序
        this.$message.success({
          message: '注册成功,即将跳转小程序',
          duration: 10000,
        })
        setTimeout(() => {
          window.location.href = this.goUrl
        }, 1000)
      } else {
        this.$message.success({
          message: '注册成功,即将跳转客户端',
          duration: 10000,
        })
        //跳转到pc
        /*window.location.href="你的电脑版地址";*/
        setTimeout(() => {
          window.open('https://kxtvat.kuaxintong.com')
        }, 1000)
      }
    },
    // 注册
    onSubmit() {
      if (!this.checked) {
        this.$message.warning({
          message: '请先同意跨信通用户协议和隐私保护政策',
        })
        return
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // var regex = /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,20}/
          // if (this.form.password.length < 8 || this.form.password.length > 20) {
          //   this.$message.warning('请输入8~20位密码')
          //   return
          // } else if (!regex.test(this.form.password)) {
          //   this.$message.warning('密码至少包含数字、字母和特殊字符其中两种')
          //   return
          // }

          const params = {
            username: this.form.username,
            code: this.form.sms,
            city: window.returnCitySN && window.returnCitySN.cname
          }
          if (this.extendedInformation) {
            params.extendValue = this.extendedInformation
          }
          if (this.extendAccount) {
            params.extendAccount = this.extendAccount
          }
          var activityCode = GetRequest()['activityCode']
          if (activityCode) {
            params.activityCode = decodeURIComponent(activityCode)
          }
          var uid = GetRequest()['UID']
          if (uid) {
            params.inviteId = decodeURIComponent(uid)
          }
          var suid = GetRequest()['SUID']
          if (suid) {
            params.uid = decodeURIComponent(suid)
          }
          var inviteUserToken = GetRequest()['inviteUserToken']
          if (inviteUserToken) {
            params.inviteUserToken = decodeURIComponent(inviteUserToken)
          }
          if (location.search) {
            const search = location.search.slice(1)
            const query = qs.parse(search)
            if (query.qdSource && query.activityType) {
              params.qdSource = query.qdSource
              params.activityType = query.activityType
            }
          }
          register(params).then((res) => {
            if (res.data.code == 200) {
              // 进行跳转判断
              this.goPAGE()
            } else {
              if (window.nc) {
                this.captchaVo = ''
                window.nc.reset()
              }
              this.$message.warning(res.data.msg)
            }
          }).catch(err => {
            if (window.nc) {
              this.captchaVo = ''
              window.nc.reset()
            }
            console.warn(err)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /**
     * 滑块
     */
    async verify() {
      if (!this.form.username) {
        this.$message.warning('请输入手机号')
        return
      }
      let reg = /^1[3456789]\d{9}$/

      if (!reg.test(this.form.username)) {
        this.$message.warning('请输入正确的手机号')
        return
      }

      this.state.smsSendBtn = true
      const acsRequest = await this.getCaptchaFn()
      if (acsRequest) {
        this.captchaVo = acsRequest
        this.$nextTick(() => {
          this.getCode()
        })
      } else {
        this.state.smsSendBtn = false
        this.state.time = 60
        this.captchaVo = ''
      }

    },
    browseRecords() {
      browseRecords({extendValue: this.extendedInformation}).then((res) => {
      })
    },
    getCaptchaFn() {
      return new Promise(resolve => {
        try {
          // 生成一个验证码对象
          // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
          var captcha = new TencentCaptcha('191202645', (res) => {
            // 第一个参数传入回调结果，结果如下：
            // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
            // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
            // CaptchaAppId       String    验证码应用ID。
            // bizState    Any       自定义透传参数。
            // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
            // console.log('callback:', res);
            // res（用户主动关闭验证码）= {ret: 2, ticket: null}
            // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
            // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
            // 此处代码仅为验证结果的展示示例，真实业务接入，建议基于ticket和errorCode情况做不同的业务处理
            if (res.ret === 0) {
              resolve({
                ticket: res.ticket,
                randstr: res.randstr
              })
            }
          }, {
            loading: false
          });
          // 调用方法，显示验证码
          captcha.show();
        } catch (error) {
          console.log(error)
          this.$message.error('验证不通过，请联系客服')
          resolve(null)
        }
      })
    }
  },
}

function GetRequest() {
  var url = location.search //获取url中"?"符后的字串
  console.log(url)
  var theRequest = new Object()
  if (url.indexOf('?') != -1) {
    var str = url.substr(1)
    var strs = str.split('&')
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}
</script>

<style scoped>

:deep(.el-input .el-input__icon) {
  width: 0.4rem;
  font-size: 0.22rem;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


:deep(.el-icon svg) {
  height: 40px;
  width: 40px;
  font-size: 40px;
}

/deep/ .el-input__inner {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

/deep/ .el-input__inner::placeholder {
  color: #999999;
}

/deep/ .el-input__prefix {
  color: #999;
}

.mengDiv {
  position: relative;
}

.mengDiv .meng {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  width: 80%;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.mengDiv .meng .button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mengDiv .meng .button .btn {
  width: 70px;
  height: 30px;
}

.login {
  min-width: 3.2rem;
  max-width: 3.75rem;
  height: 28.65rem;
  background: url('https://kxt-public.kuaxintong.com/system/web/sass/client/weChart/h5-bg.png') center no-repeat;
  background-size: 100% 100%;
  padding: 2.35rem 0 0;
  margin: 0 auto;
}

.code-box {
  position: relative;
}

.code-box /deep/ .el-input__inner {
  padding-right: 1.17rem;
}

.code-box /deep/ .el-input__suffix {
  right: 0.92rem;
}

.code {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 0.1rem;
  color: #2BB0B5;
  height: 0.2rem;
  width: 0.8rem;
  line-height: 0.2rem;
  padding-left: 0.04rem;
  border-left: 0.02rem solid #2BB0B5;
}

.el-form {
  margin: 0 auto;
  width: 3rem;
  height: 2.52rem;
}

.el-form-item {
  margin-bottom: 0.25rem;
}

.password.el-form-item {
  margin-bottom: 0.15rem;
}

/deep/ .el-input__inner {
  width: 3rem;
  height: 0.4rem;
  margin-bottom: 0.35rem;
  border-radius: 0.4rem;
  margin: 0;
}
/deep/.el-checkbox__inner{
  width: 0.1rem;
  height: 0.1rem;
}

:deep(.el-checkbox__inner::after) {
  top: 0;
  left: 0;
  right: 0;
  bottom:0.01rem;
  margin: auto;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2BB0B5;
  border-color: #2BB0B5;
  width: 0.1rem;
  height: 0.1rem;
}

/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2BB0B5;
}

/deep/ .el-checkbox.is-bordered.is-checked {
  border-color: #2BB0B5;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 80px;
}

/deep/ .el-form-item__error {
  font-size: 16px;
}

.agreement {
  font-size: 0.13rem;
  color: #999;
  display: flex;
  padding-left: 0.17rem;
  align-items: center;
}

.agreement > .el-checkbox {
  margin-right: 6px;
  /*font-size: 0.14rem; */
  zoom: 185%;
  color: #2BB0B5;
}

.el-checkbox__inner {
  border-color: #2BB0B5;
  background-color: #2BB0B5;

  /* 修改选择框的颜色 */


}

.agreement > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0.12rem;
}

.agreement > div > a {
  color: #2BB0B5;
  font-size: 0.12rem;
}

.submit {
  margin-top: 0.1rem;
  width: 3rem;
  height: 0.45rem;
  border-radius: 0.4rem;
  background-color: #2BB0B5;
  /* box-shadow: 0px 0px 20px 1px #FFCE88; */
  border: none;
  color: #fff;
  font-size: 0.16rem;
}

.myInput {
  font-size: 0.1rem;

}

element.style {
  padding-right: 0;
}
</style>
<style>
.el-message {
  min-width: 2rem;
  padding: 0.1rem;
}

.el-message .el-icon-warning,
.el-message .el-icon-success,
.el-message .el-icon-error {
  font-size: 0.2rem;
}
</style>
